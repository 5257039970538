export default defineNuxtRouteMiddleware((to, from) => {
  if (process.client) return;

  const validCategoryPaths = ["movies", "shows", "news"];
  if (validCategoryPaths.includes(to.params.category)) {
    return;
  }

  return abortNavigation();
});
